import React from 'react';
import { Link } from 'react-router-dom';

const DoctorsTwoSingleTeam = ({id,image,name,title, services}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="team-wrapper team-box-2 text-center mb-30">
                    <div className="team-thumb">
                        <img src={`${image}`} alt="" style={{ width: '310px', height: '310px' }}/>
                    </div>
                    <div className="team-member-info mt-35 mb-25">
                        <h3><Link to={`/doctorDetails/${id}`}>{name}</Link></h3>
                        <h6 className="f-500 text-up-case letter-spacing pink-color">{title}</h6>
                    </div>
                    <div className="team-social-profile mb-15">
                        <ul>
                          {services.map(servicio =>(

                            <li>{servicio.name}</li>

                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorsTwoSingleTeam;
import React, { useState, useEffect } from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../../components/Shared/Footer';
import HomeFact from '../../Home/Home/HomeFact/HomeFact';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import CounterArea from './CounterArea/CounterArea';
import DoctorOneTeamArea from './DoctorOneTeamArea/DoctorOneTeamArea';
import { useParams } from 'react-router-dom';
import ApiService from "../../../services/ApiService";

const DoctorsOne = () => {

    const { id } = useParams();
    
    var ids =[];
    var arregloObjetos = JSON.parse(id);
    console.log(arregloObjetos);
    const [doctors, setDoctors] =useState([]);


    useEffect(() => {
      arregloObjetos.map((service) => (
            ids.push(service.value)
      ));
      postFilterbyDoctorServic(ids);
      }, []);
    
      const postFilterbyDoctorServic = (ids) => {
        console.log("ids",ids);
        ApiService.postFilterbyDoctorServic(ids)
          .then((response) => {

            console.log("response",response.data.data)
            setDoctors(response.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };

    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Doctors" subtitle="Doctor" />
            <DoctorOneTeamArea doctors={doctors} postFilterbyDoctorServic={postFilterbyDoctorServic}/>
            {/*<HomeFact/>*/}
            {/*<CounterArea/>*/}
            <Footer/>
        </>
    );
};

export default DoctorsOne;
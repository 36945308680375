import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const CustomDatePicker = ({startDate,endDate, onChange, makeAppointment }) => {

  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];
 
  return (
    <div className="checkout-form-list">
      <label style={{ fontSize: 'smaller' }}>Date Range for Specialist Appointment <span className="required">*</span></label>
      <DatePicker
        dateFormat="MM/dd/yyyy"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        disabled={makeAppointment===false?(false):(true)}
        minDate={today}
      />
    </div>
  );
};

export default CustomDatePicker;
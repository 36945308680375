import React from 'react';
import { Link } from 'react-router-dom';
import HomeSingleFooterBlog from '../HomeSingleFooterBlog/HomeSingleFooterBlog';

const Footer = () => {
   return (
      <>
         <footer>
            <div className="footer-top primary-bg footer-map pos-rel pt-60 pb-40">
               <div className="container">
                  <div className="row align-items-center" >
                     <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="footer-contact-info footer-contact-info-3 mb-40">
                           <div className="footer-logo mb-35">
                              <Link to="/"><img src="img/logo/footer-logo.png" alt="" /></Link>
                           </div>
                           <div className="footer-emailing">
                             {/*<ul>
                                 <li><i className="far fa-envelope"></i>info@examplemedical.com qwq</li>
                                 <li><i className="far fa-clone"></i>examplemedical.com</li>
                                 <li><i className="far fa-flag"></i>227 Marion Street, Columbia</li>
                              </ul>*/}
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="footer-widget mb-40">
                        {/*<div className="footer-title">
                              <h3>Departments</h3>
                           </div>
                           <div className="footer-menu footer-menu-2">
                              <ul>
                                 <li><Link to="/">Home</Link></li>
                              </ul>
                           </div>*/}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;
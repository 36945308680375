import axios from "axios";
//import Auth from 'redux/constants/Auth';
//import { AUTH_TOKEN } from "redux/constants/Auth";

const URL_BASE =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

const TOKEN_PAYLOAD_KEY = "authorization";
const ENTRY_ROUTE = "/";

class ApiService {
  _instance;

  constructor() {
    let path = "";
    if (typeof window !== "undefined") {
      path = window.location.pathname;
    }

    this._instance = axios.create({ baseURL: URL_BASE });
    this._instance.interceptors.request.use(
      function (config) {

        const jwtToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMDg5NjI4ZmNiNmE3YmU2N2U3ZmQ0NzcxNjM4MzQ2OTFkMjkxOWUzZjg5MGJlNDBhMWZmZTM4OGJiOTllNmE0ZmU2YmYxODFkYzgwNjJhMjIiLCJpYXQiOjE3Mjc0ODU5OTguMjI2MzkyLCJuYmYiOjE3Mjc0ODU5OTguMjI2Mzk0LCJleHAiOjE3NTkwMjE5OTguMjE5Mzc4LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.HYYxTRd5734hsfGbEmHxGoLG_ePJR1lSJQ-7zzEOqsY025Ma3JMpYPqQAhEckCwGurNzO8aI6yJAnBaC91r2TmvYLYmr_1Xg6iRWeR6X0BfwmrwJpwUhhF4MUpmhDmBsukI51qOzxAZ9LOGPaeY7x46i8bZ3xDKMzrVEY0JQU4HHWA2_39WHgyYtYZJJJoDn4kQH83yrnKwfMGfxk9K0AswB_IAnLlwjoPaQL88wLIA1tZ4IJxk7QBE-VCIxNPjOxEcDRPWjfd9P6YLz-ogy3l5DPch02niey2t2SNpwxLYTU8LnN4QRmgBxbwhL87wbITmPJk4W6Vc6_L5J6XMIRtW2GqxHxAEc1JDtP6yzVb-NIcFyqLTIbYZUcCClpwq2XLMdiWVl6d2IDWbAcEJQf8RkBKQOjyricWuCUiHgpsNX2cnYY0UlHgVcJfHna9W27p0VAUaX3_0MORubWAoF0EOJiWgbO3SAbMJknrROpPQ5OViQnfpm3HsgDCK6Jy8KugZchxHDKlyzdWkzmqn2nmxe5r5aKfSQx5qRovy2MEZrFjbqZeb5F7wt6VeGDNsaf_ohp2KFKPCheJZu8qr4q7yx3PNU6ogApeqM_Pv-flaJBaRouW4GT8l2hcaWdfqIVjdS3UNEGwIU3kXSs_gWl1l3ZvXcLQrtzRzr3Fn1Xh8';


        if (jwtToken) {
          config.headers = {
            ...config.headers,
            [TOKEN_PAYLOAD_KEY]: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
            
          };
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this._instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401 && path !== ENTRY_ROUTE) {
          localStorage.clear();
          //window.location.pathname = ENTRY_ROUTE;
        }
        return Promise.reject(error);
      }
    );
  }

  async signin(params) {
    return await this._instance.post("/login", params);
  }

  async getPatientsOne(id) {
    return await this._instance.get(`/patients/${id}`);
  }

  
  async getSpecialties() {
    return await this._instance.get("/specialties");
  }
  
  async getSubSpecialties() {
    return await this._instance.get("/subspecialties");
  }

  async getCities(selectType) {
    return await this._instance.get(`/get-all-cities?subspecialty_type=${selectType}`);
  }

  
  async postAppointments(params) {
    return await this._instance.post(`/appointments`,params);
  }

async postAppointments2(params) {
  return await this._instance.post(`/appointmentsaddappointment`,params);
  }


  async filterByDoctor(virtualCall, speciality, subspeciality, city,type) {

    
    try {
      const response = await this._instance.get("/filter-by-doctor", {
        params: {
          virtual_call: virtualCall,
          speciality: speciality,
          subspeciality: subspeciality,
          city: city,
          type:type
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error filtering by doctor:", error);
      throw error;
    }
  }

  async get2Doctors(id) {
    return await this._instance.get(`/doctors/${id}`);
  } 

  async getDoctors() {
    return await this._instance.get(`/doctors`);
  } 

  async getServices() {
    return await this._instance.get(`/services`);
  } 



  async getServicesfilter(params) {
    
    return await this._instance.get(`/services-filter?type=${params.type}&city=${params.city}`);
  } 



  async postFilterbyDoctorServic(ids) {

    return await this._instance.post(`/filter-by-doctor-service/${ids}`);
  } 


  async getDoctorsByServices(params) {
    return await this._instance.get(`doctors-by-services?city=${params.city}&service=${params.service}`);
  }
  
  

  async sendComments(params) {
    
    return await this._instance.post(`/sendComments`,params);
  } 


  async getAllCountries() {
    
    return await this._instance.get(`/getAllCountries`);
  } 


  
  async getCitiesByGeonameId(geonameId) {
    
    return await this._instance.get(`/getCitiesByGeonameId?geonameId=${geonameId}`);
  } 


  async resendVerificationEmail(params) {
    
    return await this._instance.get(`/resendVerificationEmail?email=${params}`);
  } 



  /*async filterDoctors(virtualCall, specialty, subspecialty, city) {
    const url = `/filter-by-doctor?virtual_call=${virtualCall}&speciality=${specialty}&subspeciality=${subspecialty}&city=${encodeURIComponent(city)}`;

    try {
      const response = await this._instance.get(url);
      return response.data;
    } catch (error) {
      console.error('Error en la llamada a la API:', error);
      throw error;
    }
  }*/
}

export default new ApiService();
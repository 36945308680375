import React, { useState } from "react";

const HomeTwoSingleAbout = ({ icon, title, text, spacing }) => {
    const [showFullText, setShowFullText] = useState(false);
  
    // Función para alternar entre mostrar y ocultar el texto completo.
    const toggleText = () => {
      setShowFullText(!showFullText);
    };
  
    return (
      <div className="col-xl-6 col-lg-6 col-md-6">
        <div className={`feature-box m${spacing}`}>
          <div className="feature-small-icon mb-35">
            <img src={`img/icon/s-fea-icon-${icon}.png`} alt=""  className="centered-image"/>
          </div>
          <div className="feature-small-content">
            <h3>{title}</h3>
            <p className="custom-text-style">
              {showFullText ? text : text.slice(0, 100)}{/* Mostrar los primeros 200 caracteres */}
              {text.length > 200 && !showFullText && (
                <button onClick={toggleText} className="button-read-more">
                    Read more
                </button>
                )}
                {text.length > 200 && showFullText && (
                <button onClick={toggleText} className="button-read-less">
                    Read less
                </button>
            )}
            </p>
          </div>
        </div>
      </div>
    );
  };
  

export default HomeTwoSingleAbout;
import React from 'react';
import '../CommonSectionArea.css';

const CommonSectionArea = () => {
   return (
      <>
       <div  className="bordered-div center-horizontal" style={{ width: '780px', margin: '0 auto' }}>
            <h4>
                WAIVER
            </h4>
            <h4>
                TERMS AND CONDITIONS
            </h4>

                
                <p style={{ textAlign: 'justify' }}>
                Welcome to MTA TRUST MARKETPLACE “APP and or App website” online and official website: www.mtatrust.com. Please read these this waiver, Terms and Conditions carefully before using this website and/or the Plataform. This Health Marketplace App Waiver (“WAIVER”) is entered into by and between XXXXXX (COMPANY)XXX, hereafter referred to as “the APP”, and any individual or entity or legal representant and or other accessing of using the MTA TRUST Health Marketplace App, hereinafter refer to as “the User and or Patient”. By accessing or using the App, the User agrees to comply with and be bound by the terms and conditions outlined in this Waiver, so the user indicate that you have read, understood and agree to be bound by this agreement, and that you understand and intend this user agreement to be the legal equivalent of a signed, written contract between you and MTA TRUST TERMS, and equally binding. If you disagree with any part of the terms then you may not access the Service. We have drafted this Terms and Conditions of Use Agreement which you can understand your rights and obligations relating to the use of the Website APP and any services offered and provided by MTA TRUST. You can request a copy of the following Terms and Conditions of use (Hereafter, “The Agreement” “Terms”, or “Terms of Service”) by emailing us at team@mtatrust.com. If you are under the age of 18, you confirm that you have your parent’s or legal guardian’s written legal permission to use this Websites or related services offered and provided by MTA TRUST. Violations of these Terms and Conditions of Use may result in termination of your account or any related source of communication with or without notice.

                </p>
                <p style={{ textAlign: 'justify' }}>
                    MTA TRUST reserves the right at its discretion to change the Terms of this Agreement and will provide notice of any such changes by online posting. Your continued use and or access of the APP online and official website after the posting of any notice of change in terms shall constitute your agreement to be bound by any such changes.
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST is a medical tourism facilitator striving to simplify the process for those seeking medical and/or health treatment internationally. We work with a number of market leading doctors/clinics/hospitals in key medical tourism destinations of MEXICO who offer high quality treatment and care. We assist clients to get a high service with  competitive costs, as well as help book all the clients’ travel needs to ensure they are fully looked after during their trip.
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST does not control or operate any hospital/approved partner clinic/medical services, treatments or medical consults, neither does it operate or control any facility or service enlisted or mentioned in this website and hence, MTA TRUST can not be made liable / responsible for any delay, default, failure, improper services / deficiency in services provided by a hospital/approved partner clinic and/or for any injury, sickness, accident, discomfort, death, loss or damage of any kind whatsoever which may arise due to any act of negligence, deficiency or default in services, of the management or employees of a hospital/approved partner clinic/doctor or medical service.
                </p>
                <p style={{ textAlign: 'justify' }}>
                The contents of the APP website, diagrams, pictures, images, videos, graphics text, or any other material referred to as “content” on the website  are for informational purposes only.  The content on the website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a physician or other qualified health provider with any questions you may have regarding a medical condition or emergency.  Never disregard professional medical advice or delay in seeking it because of something you have read on the website.
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST thru the App may provide information related to health and wellness. This information is for general information purposes only and should not be consider medical advice. MTA TRUST is not responsible for the accuracy, completeness, or reliability of health-related information provided through the App.
                </p>
               <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Not A Health Care / Medical Service Provider
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST is not a healthcare service provider and as such will never provide direct advice on a client’s treatment. MTA TRUST does not control or operate any medical facility offered by the hospital or treating institution it is affiliated with in the medical tourism destination. MTA TRUST is a medical tourism facilitator and we only facilitate through advertising to doctors/medical/hospitals/companies with highly qualified and experienced professionals in the fields of dental, cosmetic and eye treatments as well as thermal treatments, children dentistry and check-ups to help to coordinate the user trip. Any treatment plans given will never be from MTA TRUST, only from independent doctors. The user accepts no liability for any advice given from any third part licensed doctors or hospitals and we always recommend that users consult their local doctor to discuss the treatment options given to them.
                </p>
                <p style={{ textAlign: 'justify' }}>
                The healthcare treatments offered through the App with the healthcare providers will be provided on the following 
                </p>
                <p style={{ textAlign: 'left' }}>
                Terms and Conditions:
                </p>
                <p style={{ textAlign: 'justify' }}>
                User or PATIENT: It is that natural person, of legal age, or with the express authorization of the parents who decides through the App, to contract freely and under their cost and responsibility any service or treatment that a doctor offers. Here we can use indistinct the term User or Patient, meaning the same of the individual that access to de App to find and or contract a medical service.
                </p>
                <p style={{ textAlign: 'justify' }}>
                DOCTOR: is that natural person or company, with authorization from the current authority of the respective authority to provide the service or treatment that develops, offers and advertises through the App. Being the only legal person responsible for the treatment, care or process to offer.
                </p>
                <p style={{ textAlign: 'justify' }}> 
                Both doctors and user or patients accept and acknowledge that MTA TRUST is not legally responsible for the medical service contracted and provided.
                </p>
                <p style={{ textAlign: 'justify' }}> 
                Patient and Doctor, are solely responsible for the use of the information they have to access the App website (username and password), which they may not share with anyone or third parties, in case they know or have a feeling that someone else is using this information to gain access, they must notify immediately to info@mtatrust.com explaining the situation and evidences. MTA TRUST will do its best to prevent and or block the access to these people. if the violations continue MTA TRUST will be free to block or remove the user from the system.
                </p>
                <p style={{ textAlign: 'justify' }}> 
                Users agree to use the App responsibly and in compliance with applicable laws and regulations. Users are responsibly fir the security of their credentials and must promptly notify MTA TRUST of any unauthorized access.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                (A) Medical Evaluation and Price Offer
                </p>
                <p style={{ textAlign: 'justify' }}> 
                After the first contact, MTA TRUST can organizes a video consultation with the doctor who is going to perform the medical treatment in MEXICO. Before the contact with the doctor, may require some tests, x-rays, previous and current health condition information, etc. to be able to evaluate your condition better and prepare the best personalized treatment plan for you. In case of a video consultation, MTA TRUST introduces the doctor to patient and give the time and opportunity to ask the doctor all of the questions in your mind related to the treatment. During your video consultation, patient and doctor will be discussing the treatment options and decide together what is the best treatment option. 
                </p>
                <p style={{ textAlign: 'justify' }}> 
                MTA TRUST reminds patient that all kind of dental procedures, healthcare procedures, transplants, replacements or surgical or otherwise carry certain risks and therefore patient and doctor or the healthcare professional have to agree about the anticipated outcome of the procedure to be undertaken.
                </p>
                <p style={{ textAlign: 'justify' }}> 
                MTA TRUST reminds patient that all kind of dental procedures, healthcare procedures, transplants, replacements or surgical or otherwise carry certain risks and therefore patient and doctor or the healthcare professional have to agree about the anticipated outcome of the procedure to be undertaken.
                </p>
                <p style={{ textAlign: 'justify' }}> 
                After the video consultation and evaluation of medical records, the doctor through the APP would prepare the quote or price offer based on the treatment details. The price offer would include only the services detailed in it. If any non-predicted cost appears after the face-to-face consultations and subsequent pre-treatment medical examination or diagnostic report once you are in MEXICO, they will be properly detailed in order to obtain your authorization and consent. If patient agree to proceed with price offer, patient and the doctor will be the only legal responsible of the treatment offered, accepted and performed. After decided the doctor and treatment, patient can choose a payment method which fits you the most. User can pay for your treatment by credit/debit card. Finance can be also available for all of the services offered by MTA TRUST.
                </p>
                <p style={{ textAlign: 'left' }}>
                The price offer presented would not include:
                </p>
                <p style={{ textAlign: 'justify' }}> 
                Insurance and any additional medical expenses for the medications or procedures in case of unforeseen complications. Make sure you discuss this with the doctor before the procedure and or advised to take medical as well as travel insurance before arriving into Mexico for your medical treatment.
                </p>
                <p style={{ textAlign: 'justify' }}> 
                International Airfare / International or Domestic Air tickets / Visa, any travel expenses, etc. are not included in the price offer.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                (B) Payment mode and terms:
                </p>
                <p style={{ textAlign: 'justify' }}>
                After the options suggested, the user will be paying the amount of your medical package in advance. This is a non-refundable amount.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Cancellation Policy:
                </p>
                <p style={{ textAlign: 'justify' }}> 
                In the event that the client’s notification of service cancellation is in writing to MTA TRUST through team@mtatrust.com and at discretion of MTA TRUST, the return of what has already been paid could be under these conditions and percentages:
                </p>
                <table style={{ border: '2px solid #000;' }}>
                    <tr>
                        <th>Cancellation notice time given to MTA TRUST.</th>
                        <th>Non-refundable amount of paid</th>
                    </tr>
                    <tr>
                        <td>More than 15 days before the appointment.</td>
                        <td>10%</td>
                    </tr>
                    <tr>
                        <td>7-14 days before the appointment</td>
                        <td>20%</td>
                    </tr>
                    <tr>
                        <td>2-6 days before the appointment</td>
                        <td>30%</td>
                    </tr>
                    <tr>
                        <td>1 day before the appointment</td>
                        <td>50%</td>
                    </tr>
                    <tr>
                        <td>For justified medical reasons</td>
                        <td>25%</td>
                    </tr>
                    <tr>
                        <td>Due to client’s decision</td>
                        <td>100%</td>
                    </tr>
                
                </table>
        
                <p style={{ textAlign: 'justify' }}> 
                If there is not a written cancellation appointment confirmed from MTA TRUST, MTA TRUST has the right of not do any payment back.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Refund
                </p>
                <p style={{ textAlign: 'justify' }}> 
                In regard to refund of unused / unutilized services (which are paid for and cancelled in advance), the refund amount would be worked out on the basis of cancellation policy as described above and the money would be accordingly refunded to the person who has made the payment to us. Please note that the refund process may take 15 days in MEXICO and may take longer for international bank transfers due to banking procedures. If client does not receive the money within the this period of time. Please contact  MTA TRUST to solve this situation.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Jurisdiction
                </p>
                <p style={{ textAlign: 'justify' }}>
                User expressly agree that exclusive jurisdiction for any dispute with MTA TRUST, or in any way relating to your use of the APP website, resides in the courts of CIUDAD DE MEXICO, MEXICO and user further agree and expressly consent to the exercise of personal jurisdiction in the courts of CIUDAD DE MEXICO, MEXICO in connection with any such dispute including any claim involving MTA TRUST or its affiliates, subsidiaries, employees, contractors, officers, directors, telecommunication providers, and content providers.
                </p>
                <p style={{ textAlign: 'justify' }}>
                If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect. No waiver of any of these Terms and Conditions shall be deemed a further or continuing waiver of such term or condition or any other term or condition.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                  Miscellaneous
                </p>
                <p style={{ textAlign: 'justify' }}>
                The APP website may be viewed internationally and may contain references to products or services not available or approved in all countries. References to a particular product or service do not imply that such product or service is appropriate or available to all persons of legal purchasing age in all locations, or that we intend to make such product or service available in such locations. Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Copyright Notice
                </p>
                <p style={{ textAlign: 'justify' }}>
                All of the App Site Materials, including the content and display of this Site are the property of MTA TRUST. The Site Materials are protected by law, including, but not limited to, MEXICO and international copyright law and trademark law. Other names and logos contained in this Site or in the Site Materials are trademarks and service marks of our affiliates and may not be used without the permission of the trademark or service mark owner.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Updates
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST regularly update and alter the content of the App website and can at any time delete or change any information, prices, or services announced by MTA TRUST. Although we do our best, we cannot guarantee that all information or prices on the App will be complete and accurate due to human or software error.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                PRIVACY POLICY
                </p>
                <p style={{ textAlign: 'justify' }}>
                For the purpose of these Terms and conditions, “Data” means all electronic data or information, including personal data submitted by you via our App Website and email. User recognize and acknowledge that in order to facilitate the provision for you, we will need to provide certain details of your personal information, including any medical information or records you provide to us, to Medical and Healthcare Professionals. User explicitly consent to the transfer and disclosure of your personal and non-personal information to these Medical and Healthcare Providers. We may also use your contact details, including your phone number and email address to contact you in future.
                </p>

                <p style={{ textAlign: 'justify' }}>
                The protection of our users’ personal information is extremely important to us. MTA TRUST endeavors to protect user privacy and the privacy of others who access the Website and use our service and facilities. MTA TRAUST will never sell to, or share your information with, any third party at any time without your permission. The information that we collect from our users is used only to facilitate the selection of desired destinations and medical procedures, and to make the necessary travel arrangements for clients.
                </p>
                <p style={{ textAlign: 'justify' }}>
                However information sent over the Internet cannot be guaranteed to be completely secure as it is subject to possible interception, loss or alteration. User understand and agree to assume the security risk for any information that provide to MTA TRUST. MTA TRUST are not responsible for any information sent over the Internet and will not be liable for any damages or other loss incurred in connection with any information sent by you to us or to a Healthcare Provider, or any information sent by us, a Healthcare Provider or any third party to you over the internet.
                </p>
                <p style={{ textAlign: 'justify' }}>
                User by providing MTA TRUST any personal or medical information hereby agrees that MTA TRUST may send it to third parties and that user hereby waives any liability towards MTA TRUST and indemnifies MTA TRUST for any use of the information by MTA TRUST. User must be an adult or have parental or guardian’s permission to submit a request or contact information on this APP website.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Tests
                </p>
                <p style={{ textAlign: 'justify' }}>
                The user or patient shall provide any tests such as blood, urine, x-rays, CT scans, MRI, colonoscopy, endoscopy, sample collection, and/or any related tests depending on the treatment procedure which doctor/surgeon has requested prior to traveling to Mexico to receive the treatment. If for any reason this information is not provided and patient decides to give these tests prior to procedure in Mexico and for any reason the tests are abnormal and unsatisfactory which leads to more tests or cancelation of treatment, the charges and costs of such tests must be paid in full and the treatment packages do not cover such charges.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                LEGAL ADIMISSION TO MEXICO
                </p>
                <p style={{ textAlign: 'justify' }}>
                Legal admission to MEXICO, MTA TRUST will not be responsible of any negative access to MEXICO, the only responsible will be the patient and or client.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                MTA TRUST Liabilities
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST shall not be liable for any delays and alterations in the program or expenses incurred – directly or indirectly – due to natural hazards, flight cancellations, accident, breakdown of machinery or equipment, breakdown of transport, weather, sickness, landslides, political closures or any untoward incidents.
                </p>
                <p style={{ textAlign: 'justify' }}>
                MTA TRUST shall not be liable for any loss, injury or damage to person, property, or otherwise in connection with any accommodation, transportation or other services, resulting – directly or indirectly – from any act of GOD, dangers, fire, accident, breakdown in machinery or equipment, breakdown of transport, wars, civil disturbances, strikes, riots, thefts, pilferage’s, epidemics, medical or custom department regulations, defaults, or any other causes beyond our control.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Discrepancies
                </p>
                <p style={{ textAlign: 'justify' }}>
                In the event of any inconsistencies or discrepancies between the terms established within the APP and or any other document and the provisions outlined in this document, the terms explicitly stated in this document shall prevail and be deemed the sole and valid terms governing the agreement between the parties. This document supersedes any conflicting terms presented in the web application, and any actions, rights, or obligations shall be interpreted in accordance with the terms specified herein. The parties expressly agree that this document serves as the authoritative source for the terms and conditions governing their relationship.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Acceptance of these terms
                </p>
                <p style={{ textAlign: 'justify' }}>
                By using and/or access the APP website, User signify the acceptance to the terms and conditions, if user do not agree with these terms and conditions, then you may not use this APP.
                </p>
                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Contact information
                </p>
                <p style={{ textAlign: 'justify' }}>
                Address: XXXXXX S.A. de C.V. XXXXXXXX, 32 Old Slip. Ciudad de México, México, Código Postal 10005.
                </p>
                <p style={{ textAlign: 'justify' }}>
                Telephone: 1-855-xxxxxxxxxxxxx
                </p>
                <p style={{ textAlign: 'justify' }}>
                Email: team@mtatrust.com
                </p>
        
        </div>

      </>
   );
};

export default CommonSectionArea;
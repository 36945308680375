import React from 'react';
import HomeTwoSingleTestimonial from '../../../components/HomeTwoSingleTestimonial/HomeTwoSingleTestimonial';
import Slider from "react-slick";

const HomeTwoTestimonials = () => {
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 4,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
      ]
   };

   const testimonialData = [
      {
         
         title: 'Excellent service',
         content: 'Very satisfied with the work done, easy communication in English with the doctor.',
         name: 'Louise',
         ubication: 'Burnaby B.C.',
      },
      {
         
         title: 'Recommended',
         content: 'happy to have access to the medical report and results of the procedure ',
         name: 'Mark',
         ubication: 'Vancouver, B.C',
      },
      {
         
         title: 'happy for the service',
         content: 'Long time looking for a safe way to contact doctors in Mexico, every vacation will take the opportunity to see the dentist',
         name: 'Edgar',
         ubication: 'Kitsilano, B.C',
      },
      {
         
         title: 'Thanks MTA TRUST ',
         content: 'Thanks MTA TRUST for the service, well-equipped and clean medical office',
         name: 'Allison',
         ubication: 'Richmond, B.C.',
      },
      
   ];

   return (
      <>
         <div className="testimonials-area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                     <div className="section-title text-center pos-rel mb-70">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5 className='color'>Testimonials</h5>
                           <h1 className='color2'>SATISFIED USERS SAY ABOUT US</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>

               <Slider className='testimonials-activation' {...settings}>
                  {testimonialData.map((testimonial, index) => (
                     <HomeTwoSingleTestimonial
                        key={index}
                        image={testimonial.image}
                        title={testimonial.title}
                        content={testimonial.content}
                        name={testimonial.name}
                        ubication={testimonial.ubication}
                     />
                  ))}
               </Slider>
            </div>
         </div>
      </>
   );
};

export default HomeTwoTestimonials;

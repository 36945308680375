import React, { useEffect, useState,useRef  } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import ApiService from '../../../services/ApiService';

import ListDoctorsModal from '../../../components/ListDoctorsModal/ListDoctorsModal';

import '../style.css';
import Button from 'react-bootstrap/Button';


const ModalCreate = (props) => {

    const {show, 
            setModalShow, 
            allDoctors, 
            cityOptions, 
            handleCityChange, 
            handleServiceChange, 
            serviceOptions, 
            selectedCity,
            selectType,
            setSelectType,
            selectedService,
            setAllDoctors
          } = props;




    useEffect(() => {



      const params ={city:selectedCity, service:selectedService }



 
      ApiService.getDoctorsByServices(params)
      .then(response => {
       
       setAllDoctors(response.data.data);
       
      })
      .catch(e => {
        console.log(e);
      });
    
       
    }, [selectType, selectedCity, selectedService])


  return (
    <>

<Modal
            show={show} 
            onHide={()=>setModalShow(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{Width: '15000px' }}
            >
            <Modal.Header>
               
                   
                    <Modal.Title>List of Specialists</Modal.Title>
            
                    <Button variant="outline-danger" onClick={()=>setModalShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                     </Button>
            
            
            </Modal.Header>

            <Modal.Body>
            <div className="row">
                <div className="col-xl-2 mb-4">
                  <div className="switch-container">
                        <label className={`switch-label ${selectType === "kids" ? 'selected' : ''}`} onClick={() => setSelectType("kids")}>Kids (under 17)</label>
                        <input type="checkbox" className="switch-checkbox" />
                  </div>
                </div>
                <div className="col-xl-2 mb-4">
                  <div className="switch-container">
                        <label className={`switch-label ${selectType === "adult" ? 'selected' : ''}`} onClick={() => setSelectType("adult")}>Adults (18+)</label>
                        <input type="checkbox" className="switch-checkbox"  />
                  </div>
                </div>
      <div className="col-xl-4 mb-4">
                <div className="appoinment-form-box appoinment-form-box-option d-flex mb-10">
                    <div className="appoint-ment-icon">
                      <img src="img/icon/cities.png" alt=""/>
                    </div>
                    <form className="appointment-form-2" action="#">
                      <label htmlFor="input" className='label-title' >Cities</label>
                      <select name="lc" id="lc" className="select_per" onChange={handleCityChange} defaultValue={selectedCity}>
                                               
                          <option selected>Choose a City</option>
                          {cityOptions}
                      </select>
                    </form>
                </div>
      </div>
      <div className="col-xl-4 mb-4">
            <div className="appoinment-form-box appoinment-form-box-option d-flex mb-40">
              <div className="appoint-ment-icon">
              <img src="img/icon/specialties.png" alt="" />
              </div>
              <form className="appointment-form-2" action="#">
                <label htmlFor="input" className='label-title'>Services</label>
              <select name="lc" id="lc" className="select_per" onChange={handleServiceChange} defaultValue={selectedService}>
                <option selected>Choose a Service</option>
                    {serviceOptions}
                </select>
              </form>
            </div>
      </div>

            </div>
            <div className="row justify-content-center">
              {Array.isArray(allDoctors) && allDoctors.length > 0 ? (
                    allDoctors.map(doctor => (
                      
                       
                       
                      <div className="col-xl-6 mb-12 justify-content-center">
                          <ListDoctorsModal
                              key={doctor.id}
                              id={doctor.id}
                              title={doctor.title}
                              name={`${doctor.first_name} ${doctor.last_name}`}
                              image={doctor.photo}
                              services ={doctor.services} 
                              selectType={selectType}
                          />
                        </div> 
                    
                    ))
                ) : (
                  <div class="alert alert-danger" role="alert">
                    There are no search results matching the entered criteria. We suggest trying again by adjusting the search parameters.
                  </div>
                )}
              </div> 
       
            </Modal.Body>
            <Modal.Footer>
               
            </Modal.Footer>
            </Modal>

    </>
  );
};

export default ModalCreate;
import React from 'react';

const CommonSectionArea = ({area_header}) => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h4>
                           Sense of Responsibility Problem -  Empowering Kids with Dental Care Opportunities
                           </h4>
                        </div>
                        <div className="about-text mb-50">
                           <p>
                              MTA Trust, since its origin, has always been focused on creating value and benefits to people`s lives and is the 
                              reason of SENTIDO RESPONSABLE program.
                           </p>
                           <p>
                             So we decided that a portion of our sales will be given to programs that help children who might not have as many 
                             opportunities to get the dental care they need. Through our board and methodology, decisions are made on how to allocate 
                             these resources in the way that has most impact on the kids and the community. 
                           </p>
                           <p>
                            It's a way for the company to give back and make a positive impact on the lives of children who need a little extra support.
                           </p>
                           <p>
                           If you are interested in more information about this fascinating program, do not hesitate to contact us.
                           </p>
                           <p>
                           If there is a need and we know how: why not help?
                           </p>
                           <p>
                           Our philosophy:
                           </p>
                        </div>
                        <div className="text-center mb-30">
                                 <h6 style={{ color: 'black', fontStyle: 'italic' }}>C.H.I.C.</h6>
                                 <h6 style={{ color: 'black', fontStyle: 'italic' }}>Creating Happiness</h6>
                                 <h6 style={{ color: 'black', fontStyle: 'italic' }}>Inspiring Confidence</h6>
                        </div>


                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;
import React, { useState, useEffect } from 'react';
import DoctorsTwoSingleTeam from '../../../../components/DoctorsTwoSingleTeam/DoctorsTwoSingleTeam';
import ApiService from '../../../../services/ApiService';

const DoctorsTwoTeamArea = () => {
    const [doctors, setDoctors] = useState([]);
   

   useEffect(() => {
      const fetchDoctors = async () => {
         try {
            const response = await ApiService.getDoctors();
   
            if (Array.isArray(response.data.data)) {
               setDoctors(response.data.data);
               console.log('Datos de los doctores:', response.data.data);
            } else {
               console.error('La propiedad "data" de la respuesta de la API no es un array:', response.data.data);
            }
         } catch (error) {
            console.error('Error fetching doctors:', error);
         }
      };
   
      fetchDoctors();
   }, []);

    return (
        <>
            <section className="team-area pt-115 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="section-title text-center pos-rel mb-70">
                                <div className="section-icon">
                                    <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text pos-rel">
                                    <h5>Our Team</h5>
                                    <h1>A Professional & Care Provider</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {Array.isArray(doctors) && doctors.length > 0 ? (
                  doctors.map(doctor => (
                     <DoctorsTwoSingleTeam
                        key={doctor.id}
                        id={doctor.id}
                        title={doctor.title}
                        name={`${doctor.first_name} ${doctor.last_name}`}
                        image={doctor.photo}
                        services={doctor.services}
                     />
                  ))
               ) : (
                  <p>No hay datos de doctores disponibles.</p>
               )}

                    </div>
                </div>
            </section>
        </>
    );
};

export default DoctorsTwoTeamArea;
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import HomeSingleFooterBlog from '../../../components/HomeSingleFooterBlog/HomeSingleFooterBlog';
import Swal from 'sweetalert2';
import ApiService from '../../../services/ApiService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const HomeTwoFooter = () => {


   
   const [isLoading, setIsLoading] = useState(false);
   const [showModal, setShowModal] = useState(false); 
   const [showModal2, setShowModal2] = useState(false); 

   const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
   });

   const onfinish = () => {
      setIsLoading(true);
   
      
      ApiService.sendComments(formData)
         .then(response => {
            Swal.fire(
               'Success!',
               response.data.message,
               'success'
            );
   
            // Limpiar los inputs
            setFormData({
               name: '',
               email: '',
               message: '',
            });
         })
         .catch(e => {
            console.log(e);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault(); // Evita que el formulario se envíe y recargue la página
      onfinish(); // Llama a la función onfinish con los datos del formulario
   };



  
   // ... (resto de tus estados y funciones)

   const handleOpenModal = () => {
      setShowModal(true);
   };

   const handleCloseModal = () => {
      setShowModal(false);
   };


   const handleOpenModal2 = () => {
      setShowModal2(true);
   };

   const handleCloseModal2 = () => {
      setShowModal2(false);
   };

   


   return (
      <>
         <footer>
            <div className="footer-top theme-bg pt-100">
               <div className="container">
                  <div className="footer-top-form mb-60">
                     <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                           <div className="footer-logo-2">
                              <img src="img/logo/logo-2.png" alt=""/>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="footer-mid pb-60">
                     <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-contact-info-2">

                                  {/*
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-1.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       <span>Mon to Fri : 08h30 - 18h00</span>
                                       <h4>+898 68679 575 09</h4>
                                    </div>
                                 </div>*/}
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-2.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       <span>do you have a question?</span>
                                       <h4>team@mtatrust.com</h4>
                                    </div>
                                 </div>
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-2.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       <span>socials network</span>
                                       <ul>
                                          <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                          <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                          <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                          <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                          <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-3.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       <span>Recommended Browsers</span>
                                       <span>
                                          <img src="img/Google_Chrome.png" alt="" width="20px" height="20px" style={{ marginRight: '10px' }} />
                                          <h8>Google Chrome</h8>
                                       </span>
                                       <span>
                                          <img src="img/Firefox.png" alt="" width="20px" height="20px" style={{ marginRight: '10px' }} />
                                          <h8>Mozilla Firefox</h8>
                                       </span>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-title">
                                 {/* <h3>Departments</h3> */}
                              </div>
                              <div className="footer-menu footer-menu-2 fix">
                                 <ul>
                                    <li><a href="/">Home</a></li>
                                   
                                    <li><Link to="/" onClick={handleOpenModal2}>Our Philosophy</Link></li>   

                                    <li><Link to="/" onClick={handleOpenModal}>
                                           Our Mission
                                    </Link></li>  
                                    <li><Link to="/terms">Waiver: Terms and Conditions</Link></li>                                                     
                                 </ul>
                              </div>
                           </div>
                        </div>


                        <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="footer-contact-form">
                              <div className="footer-title">
                                 <h3>Let us know your comments</h3>
                              </div>
                              <form onSubmit={handleSubmit}>
                                 <div className="form-group">
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Your Name"
                                       name="name"
                                       value={formData.name}
                                       onChange={handleInputChange}
                                    />
                                 </div>
                                 <br />
                                 <div className="form-group">
                                    <input
                                       type="email"
                                       className="form-control"
                                       placeholder="Your Email"
                                       name="email"
                                       value={formData.email}
                                       onChange={handleInputChange}
                                    />
                                 </div>
                                 <br />
                                 <div className="form-group">
                                    <textarea
                                       className="form-control"
                                       placeholder="Your Message"
                                       name="message"
                                       value={formData.message}
                                       onChange={handleInputChange}
                                    ></textarea>
                                 </div>
                                 <br />
                                 <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isLoading}
                                 >
                                    {isLoading ? 'Sending...' : 'Send Message'}
                                 </button>
                              </form>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </footer>

         <Modal
            show={showModal} 
            onHide={()=>handleCloseModal()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{Width: '9000px' }}
            >
            <Modal.Header>
               
                   
                    <Modal.Title>Our Mission</Modal.Title>
                    <Button variant="outline-danger" onClick={()=>handleCloseModal()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                     </Button>
            </Modal.Header>

            <Modal.Body >
            
            {/*
            <div className="text-center d-flex align-items-center justify-content-center">
               <img src="img/logo/footer-logo.png" alt="" />
            </div>
            <br/>
            <br/>
            <h4 style={{ textAlign: 'left' }}>A NEW RELIABLE HEALTH SOLUTION:</h4>
            <br/>
            <br/>
            <h3 style={{ textAlign: 'right' }}>MOVE FROM GOOD TO GREAT</h3>
            <br/>
            <br/>
            <br/>
            <br/>*/}
            <p className="text-center">
               MTA TRUST MISSION
            </p>


            <p>
            Our Mission is to revolutionize the medical tourism industry by creatin a seamless, personalized, and transparent healthcare expedience for patients or user (“User”) globally an to empower the “User” by facilitating access to top-tier global healthcare services. We aim to provide solutions so both “User” and Doctors can contact each other and arrange a face-to-face or remote appointments: always looking for: i) the best service, ii) the highest technology possible and iii) correct medical practices. In an accompanied, safe, professional, and always reliable way during the whole process.
            
            </p>
            </Modal.Body>
            <Modal.Footer>
               
            </Modal.Footer>
            </Modal>


            <Modal
            show={showModal2} 
            onHide={()=>handleCloseModal2()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{Width: '9000px' }}
            >
            <Modal.Header>
               
                   
                    <Modal.Title>OUR PHILOSOPHY</Modal.Title>
                    <Button variant="outline-danger" onClick={()=>handleCloseModal2()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                     </Button>
            </Modal.Header>

            <Modal.Body >


            {/*
            <div className="text-center d-flex align-items-center justify-content-center">
               <img src="img/logo/footer-logo.png" alt="" />
            </div>
            <br/>
            <br/>
            <h4 style={{ textAlign: 'left' }}>A NEW RELIABLE HEALTH SOLUTION:</h4>
            <br/>
            <br/>
            <h3 style={{ textAlign: 'right' }}>MOVE FROM GOOD TO GREAT</h3>
            <br/>
            <br/>
            <br/>
            <br/>
            */}
            <p className="text-center">
            PHILOSOPHY: OUR ESSENCE
            </p>


            <p>
            Since our planning, development and launch of the platform, it has always been to be a technological market where there are users looking for a safe, agile and very high quality healthcare service; and on the other hand, medical service providers with the highest level of demand for medical service with global preparation and technology are promoted.

            We will always follow our pillars of maximum respect for the user, always updating technology and innovation, always carrying out the operation of the business from the human side as the first term. Offering a safe, reliable and professional service.
            
            </p>
            </Modal.Body>
            <Modal.Footer>
               
            </Modal.Footer>
            </Modal>

      </>
   );
};

export default HomeTwoFooter;
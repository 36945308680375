import React, { useState, useEffect } from 'react';
import HomeTwoSingleTeam from '../../../components/HomeTwoSingleTeam/HomeTwoSingleTeam';
import Slider from "react-slick";
import ApiService from '../../../services/ApiService';



const HomeTwoTeamArea = () => {
   const [doctors, setDoctors] = useState([]);
   

   useEffect(() => {
      const fetchDoctors = async () => {
         try {
            const response = await ApiService.getDoctors();
   
            if (Array.isArray(response.data.data)) {
               setDoctors(response.data.data);
               console.log('Datos de los doctores:', response.data.data);
            } else {
               console.error('La propiedad "data" de la respuesta de la API no es un array:', response.data.data);
            }
         } catch (error) {
            console.error('Error fetching doctors:', error);
         }
      };
   
      fetchDoctors();
   }, []);
   
   const settings = {
      dots: true,
      infinite: false,
      speed: 300,
      arrows: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1
            }

         }
      ]

   };
   return (
      <>
         <section className="team-area pt-115 pb-75">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section-title text-center pos-rel mb-70">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5 className='color'></h5>
                           <h1 className='color2'>Specialist</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>

               {/* <div className="row team-activation"> */}

                  <Slider className='testimonials-activation team__area' {...settings}>

                  {Array.isArray(doctors) && doctors.length > 0 ? (
                  doctors.map(doctor => (
                     <HomeTwoSingleTeam
                        key={doctor.id}
                        id={doctor.id}
                        title={doctor.title}
                        subtitle={`${doctor.first_name} ${doctor.last_name}`}
                        image={doctor.photo}
                     />
                  ))
               ) : (
                  <p>No hay datos de doctores disponibles.</p>
               )}

                  </Slider>
               
               {/* </div> */} 
            </div>
         </section>
      
      </>
   );
};

export default HomeTwoTeamArea;
import React, { useState } from 'react';
import CommonSectionArea from '../../../components/CommonSectionArea/CommonSectionArea';
import ModalVideo from 'react-modal-video';
import Slider from "react-slick";

import 'react-modal-video/scss/modal-video.scss';
import useGlobalContext from '../../../hooks/useGlobalContext';

const AboutArea = () => {
   const [isOpen, setOpen] = useState(false);

   const { SlickArrowLeft, SlickArrowRight} = useGlobalContext();
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      fade: true,
      arrows: true,
      prevArrow: <SlickArrowLeft/>,
      nextArrow: <SlickArrowRight/>,

   };

   return (
      <>



          {/*
         <ModalVideo channel='youtube' autoplay isOpen={isOpen}
            videoId="I3u3lFA9GX4" onClose={() => setOpen(false)} />*/}

         <CommonSectionArea area_header={
            <div className="about-left-side pos-rel mb-30">
               {/*<div className="about-front-img pos-rel">
                  <img src="img/about/about-img.jpg" alt="" />
                  <button onClick={() => setOpen(true)} className="popup-video about-video-btn white-video-btn">
                     <i className="fas fa-play"></i></button>
               </div>*/}
               <br/>
               <br/>
               <br/>
               <br/>
               <Slider className='postbox__gallery' {...settings}>
                  <img src="img/blog/im1.jpeg" alt="blog" />
                  <img src="img/blog/im2.jpeg" alt="blog" />
               </Slider>
               <div className="about-shape">
                  <img src="img/about/about-shape.png" alt="" />
               </div>
            </div>
         } />
      </>
   );
};

export default AboutArea;
import { title } from 'process';
import React from 'react';

const HomeTwoSingleTeam = ({id,image,title,subtitle}) => {
   
   return (
      <>
         <div className="col-xl-12">
            <div className="team-box pos-rel mb-50">
               <div className="team-thumb">
                  <img
              src={`${image}`}
              alt=""
              style={{ width: '170px', height: '200px', borderRadius: '10px',  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }} // Ajusta el tamaño aquí
            />
               </div>
               <div className="team-author-info">
                  <span>{title}</span>{/*Puesto*/}
                  <h6>{subtitle}</h6>{/* nombre*/}
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeTwoSingleTeam;
import React from 'react';

import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import CommonSectionArea from './CommonSectionArea2/CommonSectionArea';
const Terms = () => {
   return (
      <>
      <HomeHeader/>
      <CommonPageHeader/>
      <CommonSectionArea/>

   
      </>
   );
};

export default Terms;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeSingleTeam from "../../../../components/HomeSingleTeam/HomeSingleTeam";
import ApiService from "../../../../services/ApiService";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";

const DoctorOneTeamArea = (props) => {
  const { doctors, postFilterbyDoctorServic } = props;
  const [arregloDeObjetos2, setArregloDeObjetos2] = useState([]);
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [virtualCall, setVirtualCall] = useState(0);
  const [city, setCity] = useState("");
  const [type, setType] = useState("");
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [services2, setServices2] = useState([]);

  useEffect(() => {
    getCities();
  }, []);

  const getCities = () => {
    ApiService.getCities()
      .then((response) => {
        console.log(response.data); // Imprime las ciudades
        setCities(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const cityOptions =
    cities &&
    cities.map((city) => (
      <option key={city} value={city}>
        {city}
      </option>
    ));

  console.log(cityOptions);
  // Define las variables seleccionado y manejarSeleccion
  const [seleccionado, setSeleccionado] = React.useState(-1); // Inicializa seleccionado con un valor predeterminado

  // Define la función manejarSeleccion
  const manejarSeleccion = (opcion) => {
    setSeleccionado(opcion);
  };

  // --------- KIDS/ADULTS

  // Define las variables seleccionado y manejarSeleccion
  const [selecc, setSelecc] = React.useState(-1); // Inicializa seleccionado con un valor predeterminado
  const kids = "kids";
  const adult = "adult";
  // Define la función manejarSeleccion
  const manejarSelecc = (opcion) => {
    setSelecc(opcion);
  };

  const handleClick = () => {
    if (
      seleccionado !== -1 &&
      selecc !== -1 &&
      city !== "-1" &&
      selectedServices.length > 0
    ) {
      var ids = [];
      selectedServices.map((service) => ids.push(service.value));
      postFilterbyDoctorServic(ids);
    } else {
      // Si falta algún campo, muestra un mensaje de error o toma la acción apropiada.
      console.log(
        "Por favor, completa todos los campos antes de realizar la búsqueda."
      );
    }
  };

  useEffect(() => {
    if (seleccionado != -1 && selecc != -1) {
      getServicesfilter();
    }
  }, [seleccionado, selecc]);

  const getServicesfilter = () => {
    const params = { type: selecc, virtual_call: seleccionado };
    ApiService.getServicesfilter(params)
      .then((response) => {
        console.log("soy yo", response.data.data);
        setServices2(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const options3 = services2.map((service) => ({
    label: service.name,
    value: service.id, // O el identificador único de tu servicio
    description:service.description  
  }));


  const customItemRenderer = ({ option, onClick, checked }) => {
    console.log("option ", option);
    return (
      <div title={option.description} style={{ display: 'flex', alignItems: 'center' }}>
        <input type="checkbox" onClick={onClick} checked={checked} style={{ marginRight: '8px' }} />
        <span>{option.label}</span>
      </div>
    );
  };

  return (
    <>
      <section className="team-area pt-115 pb-55">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div className="section-title pos-rel mb-30">
                <div className="section-text pos-rel">
                  <h5 className="color">Our Team</h5>
                  <h1 className="color2">A Professional & Care Provider</h1>
                </div>
              </div>
            </div>

            {/*  FILTRO */}

            <div className="col-xl-3">
              <div className="appoinment-form-box appoinment-form-box-option d-flex mb-40">
                <div className="col-xl-10 offset-xl-2 col-lg-20 col-md-10 mt-8">
                  <div className="slider-right-1">
                    <div className="row">
                      <div className="search-form">
                        <h2>Find A Dentist</h2>
                      </div>

                      <div className="filter-column">
                        <div className="row">
                          <div className="col-xl-6 mb-4">
                            <div className="switch-container">
                              <label
                                className={`switch-label ${
                                  seleccionado === 0 ? "selected" : ""
                                }`}
                                onClick={() => manejarSeleccion(0)}
                              >
                                In Person
                              </label>
                              <input
                                type="checkbox"
                                className="switch-checkbox"
                                checked={seleccionado === 0}
                                onChange={() => manejarSeleccion(0)}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 mb-4">
                            <div className="switch-container">
                              <label
                                className={`switch-label ${
                                  seleccionado === 1 ? "selected" : ""
                                }`}
                                onClick={() => manejarSeleccion(1)}
                              >
                                On Line
                              </label>
                              <input
                                type="checkbox"
                                className="switch-checkbox"
                                checked={seleccionado === 1}
                                onChange={() => manejarSeleccion(1)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 mb-4">
                            <div className="switch-container">
                              <label
                                className={`switch-label ${
                                  selecc === kids ? "selected" : ""
                                }`}
                                onClick={() => manejarSelecc(kids)}
                              >
                                Kids
                              </label>
                              <input
                                type="checkbox"
                                className="switch-checkbox"
                                checked={selecc === kids}
                                onChange={() => manejarSelecc(kids)}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 mb-4">
                            <div className="switch-container">
                              <label
                                className={`switch-label ${
                                  selecc === adult ? "selected" : ""
                                }`}
                                onClick={() => manejarSelecc(adult)}
                              >
                                Adults
                              </label>
                              <input
                                type="checkbox"
                                className="switch-checkbox"
                                checked={selecc === adult}
                                onChange={() => manejarSelecc(adult)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-column">
                        <div className="appoinment-form-box appoinment-form-box-option d-flex mb-40">
                          <form className="appointment-form-2" action="#">
                            <label htmlFor="input" className="label-title">
                              Cities
                            </label>
                            <select name="lc" id="lc" className="select_per">
                              <option defaultValue="-1">Choose a City</option>
                              {cityOptions}
                            </select>
                          </form>
                        </div>
                      </div>

                      <div className="filter-column">
                        <form className="appointment-form-1" action="#">
                        <div style={{ width: '100%' }}>
                          <label htmlFor="input" className="label-title">
                            Services
                          </label>
                                          
                          <div >
                            <MultiSelect 
                              options={options3}
                                                
                              value={selectedServices}
                                     
                              onChange={setSelectedServices}
                              labelledBy="Select service"
                                                 

                              ItemRenderer={customItemRenderer}
                              id="ms" 
                              name="ms" 
                              />
                                                
                                                
                          </div>
                          </div> 
                        </form>
                      </div>

                      <div className="filter-column">
                        <div className="col-xl-10 mb-1">
                          <div className="inner caregive-btn text-center">
                            <button
                              className="primary_btn gray-btn-border"
                              type="button"
                              onClick={handleClick}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {doctors.length > 0 ? (
            <div className="row">
              {doctors.map((doctor) => (
                <HomeSingleTeam
                  key={doctor.id}
                  image={doctor.photo}
                  title={`${doctor.first_name} ${doctor.last_name}`}
                  subtitle={`${doctor.title}`}
                  id={doctor.id}
                />
              ))}
            </div>
          ) : (
            <div className="row">
              <p>No results found.</p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default DoctorOneTeamArea;

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import HomeTwoSingleAbout from '../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout';

const HomeTwoAbout = () => {

   const initialText = `MTA TRUST is an online marketplace that connects medical professionals who offer High Quality Dental Services with people looking for a fair and high-tech medical service. MTA TRUST offers to patients the opportunity to have access to affordable dental care through a reliable system, being able to choose between in-person attention while on vacations or travel in Mexico, or to be attended remotely via video conference. \n 

   We are a sophisticated team with proven experience in the medical service industry, with deep knowledge of the local doctors market, and sensitive to patient expectations in terms of health. \n
   
   Now you have in your hands the decision to be medically treated abroad (Medical Tourism Abroad), to be attended by a professional in English and with access to your medical report of treatment in your same language. \n
   
   Secure Payment: each medical provider accepts that MTA TRUST keeps in custody the amount paid for the treatment until the contracted service has been completed correctly.\n
   
   In MTA TRUST, we want to make it easier for Canadian and American residents to travel to different key countries and receive medical treatment by qualified specialists; we will approach them in an accompanied, safe, professional, and always reliable way. Do not hesitate to know more about us and our service, please visit our terms and conditions section on this webpage. Our goal is to be the best and highest qualified Medical Tourism Facilitator in the market.\n`;
   
   const paragraphs = initialText.split('\n\n');

   const textWithLineBreaks = paragraphs.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
   ));

     const [showFullText, setShowFullText] = useState(false);
   
     const toggleText = () => {
       setShowFullText(!showFullText);
     };
     const textToShow = showFullText ? textWithLineBreaks : textWithLineBreaks.slice(0, 1);

   return (
      <>
         <section id="about-section" className="about-area about-area-mid pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-12">
                     <div className="row">                        

                        <div className="col-xl-15 col-lg-12 col-md-12">
                           <div className="row">
                              <HomeTwoSingleAbout                              
                                 icon="1"
                                 title="General Dentistry"
                                 spacing="b-40"
                                 text="Encompasses a wide range of dental care services aimed at maintaining oral health, preventing dental issues, diagnosing diseases, and providing treatment for various conditions affecting the teeth, gums, and mouth. It is the primary field of dentistry that focuses on comprehensive care to ensure long-term oral health. General dentists are the primary dental care providers for patients of all ages. They manage the overall oral health care needs of their patients, including preventive, restorative, and cosmetic services."
                              />
                              <HomeTwoSingleAbout                              
                                 icon="4"
                                 title="Endodontics"
                                 spacing="t-40"
                                 text="Is a specialized branch of dentistry that deals with the studyand treatment of the dental pulp and the tissues surrounding the root of a tooth. Endodontic treatment is commonly referred to as a 'root canal,' which is the most well-known procedure within this specialty.are dentists who specialize in saving teeth through these procedures, which allow patients to retain teeth that might otherwise need to be extracted due to infection or decay that has reached the pulp"
                              />
                              <HomeTwoSingleAbout                              
                                 icon="3"
                                 title="Oral and Maxillofacial Surgery,"
                                 spacing="b-30"
                                 text="Is an important branch of dentistry that specializes in thesurgical treatment of a wide range of diseases, injuries, and defects in the head, neck, face, jaws, and the hard and soft tissues of the oral (mouth) and maxillofacial (jaws and face) region. It is recognized internationally as a surgical specialty."
                              />
                              <HomeTwoSingleAbout                              
                                 icon="2"
                                 title="Orthodontics"
                                 spacing="t-40 mb-30"
                                 text="Is a specialized branch of dentistry that focuses on diagnosing, preventing, and treating dental and facial irregularities, known as malocclusions. Orthodontic treatment is aimed at creating a healthy, functional bite, which involves the correct alignment of teeth and jaws for optimal chewing, speaking, and aesthetic appearance."
                              />
                               <HomeTwoSingleAbout                              
                                 icon="1"
                                 title="Prosthodontics"
                                 spacing="t-40 mb-30"
                                 text="Is a dental speciality that focuses on the restoration and replacement of lost or damaged teeth with artificial devices. This dental specialty encompasses the design, manufacture, and fitting of prosthetic devices, such as dentures, bridges, crowns, and implants, to restore functional and aesthetic aspects of the oral cavity."
                              />
                               <HomeTwoSingleAbout                              
                                 icon="2"
                                 title="Pediatric Dentistry"
                                 spacing="t-40 mb-30"
                                 text="Is a specialized branch of dentistry that focuses on the oral health care of infants, children, adolescents, and patients with special health care needs. Pediatric dentists are dedicated to the prevention, diagnosis, and treatment of dental diseases, as well as the management of dental growth and development in younger patients. This specialization emphasizes the importance of establishing a comprehensive and preventive oral health foundation that helps ensure a lifetime of good dental health."
                              />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-11">
                     <div className="about-right-side pt-25 mb-30">
                        <div className="about-title mb-20">
                           <h5 className="color">About Us</h5>
                           <h2 className="color2">Medical Tourism Abroad.</h2>
                        </div>
                        <div className="about-text">
                           {textToShow}
                           {initialText.length > 400 && (
                           <button onClick={toggleText} className="button-read-more">
                              {showFullText ? "Read less" : "Read more"}
                           </button>
                           )}
                        </div>
                        <div className="about-text-list mb-40">
                           <ul>
                              <li><i className="fa fa-check"></i><span>Cost - Effective Dental Care.</span></li>
                              <li><i className="fa fa-check"></i><span>High - Quality Dental Professionals.</span></li>
                              <li><i className="fa fa-check"></i><span>Travel Opportunities.</span></li>
                           </ul>
                        </div>
                        <div className="button-area">
                           <a href="/#" className="primary_btn btn-icon ml-0"><span>+</span>Home</a>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAbout;
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ApiService from "../../../../services/ApiService";
import {parseISO, format } from 'date-fns';
import Swal from 'sweetalert2';

export default function FormLoginAppointment(props) {

    const { setFormData, formData, setRegisteredPatient, setAdditionalFields, setSelectedOption, setBirthdays } = props;

    const [formDataLogin, setFormDataLogin] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [errors, setErrors] = useState({ email: '', password: '' });
    const [id, setId] = useState(0);
    const [arrayIds, setSetArrayIds] = useState([]);

    const [errorLogin, setErrorLogin]= useState('');

    const[userId, setUserId]=useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDataLogin({
            ...formDataLogin,
            [name]: value
        });
    };

    const validateForm = () => {
        let valid = true;
        let errors = {};

        // Validación de Email
        if (!formDataLogin.email) {
            errors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formDataLogin.email)) {
            errors.email = 'Email is invalid';
            valid = false;
        }

        // Validación de Contraseña
        if (!formDataLogin.password) {
            errors.password = 'Password is required';
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    useEffect(() => {

        if(id !== 0){
            
            getPatientsOne();
        }

    }, [id]);


    useEffect(() => {

        if(arrayIds.length !== 0){
            
            setAdditionalFields([]);
            arrayIds.map((id) => {

                getPatientsMany(id); // Llama a la función para cada id
                setBirthdays(prevBirthdays => [...prevBirthdays, { month: '', day: '', year: '' }]); 

           });
            
        }

    }, [arrayIds]);


    const handleSubmitLogin = (e) => {
        e.preventDefault();

        if (validateForm()) {
            
            ApiService.signin(formDataLogin)
            .then(response => {
                    
                
                    
                
                      
                    if(response.data.response === "success"){
                       
                        setErrorLogin('');
                        setUserId(response.data.user.id);

                        if(response.data.user.patient !== null){
                            setId(response.data.user.patient.id);
                        }else{

                           setSelectedOption('client');
                            setSetArrayIds(response.data.user.listpatients);

                            if(response.data.user.client !== null){

                                setFormData({ ...formData, 

                                    firstName: response.data.user.client.first_name,
                                    lastName:  response.data.user.client.last_name,
                                    user_id:response.data.user.client.user.id
                           
                                });

                            }
                        }
                        

                        
                     

                    }
    
              
            })
            .catch(e => {

               
              console.log(e);
              setErrorLogin(e.response.data.error);
            });
        }
    };
    


    const getPatientsOne = () => {
        
            ApiService.getPatientsOne(id)
            .then(response => {
                
               setSelectedOption('patient');
               const parsedDate = parseISO(response.data.data.date_of_birth);

               setLoading(true);
               setRegisteredPatient(true);

               
               setFormData({ ...formData, 
                            patient_id: response.data.data.id, 
                            firstName: response.data.data.first_name,
                            lastName: response.data.data.last_name,
                            birthdate:parsedDate,
                            user_id:userId
                        });
            })
            .catch(e => {
              console.log(e);
            });
    
    };


    const getPatientsMany = (id) => {
        
        ApiService.getPatientsOne(id)
        .then(response => {
   
            setRegisteredPatient(true);
          
           
           const parsedDate = parseISO(response.data.data.date_of_birth);
         
           setAdditionalFields(prevState => [
            ...prevState,
                {
                    patient_id:response.data.data.id,
                    user_id:response.data.data.user_id,
                    first_name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    date_of_birth: response.data.data.date_of_birth,
                    view_birth: parsedDate,
                    services:[],
                    services_2:[]
                }
           ]);

 
        })
        .catch(e => {
          console.log(e);
        });

    };


    const handleVerificationEmail = () =>{

        setLoading2(true);

        ApiService.resendVerificationEmail(formDataLogin.email)
        .then(response => {
            
            setErrorLogin('')
            setLoading2(false);
            Swal.fire({
                icon: 'success',
                title: response.data.message,
                text: 'check your email please!!',
                
            });
            
        })
        .catch(e => {
          setLoading2(false);
          console.log(e);
        });

    }


    return (
        <>
 
 <div className="row g-4" style={{ width: '100%' }}>
    <div className="col-md-4">
        <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formDataLogin.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
                {errors.email}
            </Form.Control.Feedback>
            
            {errorLogin && (
                <div className="badge bg-danger text-wrap">
                    {errorLogin}
                </div>
            )}
        </Form.Group>
    </div>
    <div className="col-md-4">
        <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={formDataLogin.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
                {errors.password}
            </Form.Control.Feedback>
        </Form.Group>
    </div>
    
    <div className="col-md-4">
            
            <div style={{ marginBottom: '27px' }}></div>            
    
            <Button variant="primary" disabled={loading} onClick={handleSubmitLogin} style={{ marginRight: '27px' }}>
               {loading ? 'Loading...' : 'Login'}
            </Button>
            
            {errorLogin === "Unverified user" && (
                <Button variant="success" disabled={loading2} onClick={handleVerificationEmail}>
                    {loading ? 'Loading...' : 'Resend Verification Email'}
                </Button>
            )}
            
        
    </div>
</div>
        <br/>    
        </>
    );
}

import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../../components/Shared/Footer';
import HomeFact from '../../Home/Home/HomeFact/HomeFact';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import CounterArea from '../DoctorsOne/CounterArea/CounterArea';
import DoctorsTwoTeamArea from './DoctorsTwoTeamArea/DoctorsTwoTeamArea';
import HomeTwoFooter from '../../HomeTwo/HomeTwoFooter/HomeTwoFooter';

const DoctorsTwo = () => {
    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Doctors" subtitle="Doctors" />
            <DoctorsTwoTeamArea/>
            {/*<HomeFact />
            <CounterArea/>*/}
            <HomeTwoFooter/>
        </>
    );
};

export default DoctorsTwo;